const pages = [
    {
        //用户列表
        path : 'user/userList',
        name : 'user.userList',
        component:()=>import('../../views/User/UserList'),
    },
    {
        //批量注册用户
        path: 'user/userBulk',
        name:'user.bulkUser',
        component:()=>import('../../views/User/userBulk'),
    },
    {
        //批量迁移用户
        path: 'user/userMove/:userNameList?',
        name:'user.moveUser',
        component:()=>import('../../views/User/userMove')
    },
    {
        //批量回收用户
        path:'user/Recovery/:userList?',
        name:'user.recovery',
        component:()=>import('../../views/User/userRecovery')
    },
    {
        //用户下载记录
        path:'user/DownLoad/:username',
        name:'user.Download',
        component:()=>import('../../views/User/userDownLoad'),
    },
    {
        //用户使用卡密充值记录
        path:'user/userUseRecKey/:username',
        name: 'user.userUseRecKey',
        component:()=>import('../../views/User/userUseRecKey'),
    },
    {
        //用户批量充值
        path: 'user/userAddMoneyBatch/:userList?',
        name: 'user.userAddMoneyBatch',
        component:()=>import('../../views/User/userAddMoneyBatch'),
    },
    {
        //批量注册用户日志
        path: 'user/BatchRegUserLog',
        name: 'user.BatchRegUserLog',
        component:()=>import('../../views/User/BatchRegUserLog'),

    },

];

export default pages;