const lang = {
    AgentRec: {
        'TableColumn.label.id': 'serial number',
        'TableColumn.label.leader': 'superior',
        'TableColumn.label.agent': 'Agent',
        'TableColumn.label.use': 'Times/use',
        'TableColumn.label.money': 'Times/Remainder',
        'TableColumn.label.all': 'Times/Total',
        'el-button.sort': 'sort data',
    },
    AgentReg: {
        'TableColumn.label.id': 'serial number',
        'TableColumn.label.leader': 'superior',
        'TableColumn.label.agent': 'Agent',
        'TableColumn.label.use': 'register/Use',
        'TableColumn.label.money': 'Register/Money',
        'TableColumn.label.all': 'Registration/Total',
        'el-button.sort': 'sort data',
    },
    CutMoney: {
        'TableColumn.label.id': 'serial number',
        'TableColumn.label.agent': 'Agent',
        'TableColumn.label.username': 'Account',
        'TableColumn.label.cut': 'cutting',
        'TableColumn.label.money-1': 'Balance',
        'TableColumn.label.money-2': 'Expiration time',
        'TableColumn.label.all-1': 'Total',
        'TableColumn.label.UserMoneyDay': 'Number of days',
        'el-button.sort': 'sort data',
    }
};
export default lang;