const lang = {
    //result page [result.vue]
    'result':{
        'html.button': 'Return to superior',
        'html.el-tag': 'Number of results',
        'mounted.wait': 'Rendering data..',
        'mounted.success': 'Data has been rendered',
        'mounted.fail': 'Loading data error',
    }
};

export default lang;