<template>
  <viewComponents></viewComponents>
</template>

<script>
import viewComponents from "./components/viewComponents";

export default {
  name: 'App',
  components: {
      viewComponents
  }
}
</script>

<style>
html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}

</style>
