const lang = {
    'agentNum':{
        'explain':'用于统计某个时间段下一级新增的代理商数量',
    },
    'cut':{
        'explain':'统计下级代理商的用户某个时间段切割数量',
    },
    'userNum':{
        'explain':'用于统计某个时间段下级代理商新增的用户数量',
    },
    //目录下的[template]文件夹
    'template.viewFilterFromTime':{
        'html.el-form-item.label':'范围',
        'html.el-button.filter':'筛选数据',
        'html.el-button.return':'取消筛选',
        'html.el-button.reset':'清空表单',
        'submit.error.length':'筛选条件异常',
    },
    'template.viewOneClickTime':{
        'html.el-button.filter':'快速筛选',
    },
    'template.viewPagination':{
        'html.el-tag.page':'当前页数',
    },
    'template.viewTable':{
        'html.el-button.refresh':'刷新页面',
        'html.el-button.alertTips':'数据说明',
        'html.el-button.openFilterTime':'筛选时间',
        'html.el-button.outputReport':'导出数据',
        'html.el-tag.time':'生成时间',
        'html.el-tag.all':'报表总和',
        'html.element-loading-text':'正在生成数据,请勿关闭页面..',
        'html.el-checkbox.label':'包含下级',
        'html.el-table.loading':'请耐心等待生成完成..',
        'html.el-table-column.id':'编号',
        'html.el-table-column.object':'账号',
        'html.el-table-column.result':'数据',
        'getReportData.wait':'正在请求报表数据..[可能需要几十秒时间]',
        'getReportData.error.null':'请求报表数据异常',
        'recvFilterNewValue.tips':'已进入筛选模式 时间:',
        'outputReport.wait':'正在准备导出数据..',
        'outputReport.success':'导出数据完成',
    },
};
export default lang;