const lang = {
    //head component
    'memberHeader':{
        'title':'Background management system',
        'username':'Account',
        'index': 'personal center',
        'loginOut': 'Log out',
        'setSelfNewPass': 'Change password',
    },
    //navigation bar component
    'Navbar':{
        //column
        'items':{
            'nav.user': 'User management',
            'nav.agent':'Agent management',
            'nav.key': 'Code management',
            'nav.agentReport':'Agent Statistics',
            'nav.log': 'Log query',
            'nav.userReport': 'User Statistics',
            'nav.BrandModelReport': 'Model statistics',
            'nav.ReportForms': 'Report statistics',
        },
        //Subpage
        'pages':{
            'user.userList':'User management',
            'user.moveUser': 'Migrate user',
            'agent.agentList': 'Subordinate management',
            'agent.addAgent': 'Add subordinate',
            'user.bulkUser': 'bulk registration',
            'user.recovery': 'bulk recovery',
            'key.keyList': 'Code management',
            'key.makeKey': 'Generate code',
            'key.moveKey': 'Migrate code',
            'key.recovery': 'recovery code',//your own code
            'agentReport.userNum': 'Add user',
            'agentReport.agentNum': 'Agent added',
            'agentReport.cut': 'agent cut',
            'userReport.cut': 'User cut',
            'userReport.KeyByRec': 'User recharge',
            'userReport.model': 'Popular Model',
            'userReport.brand': 'Popular Brands',
            'log.agentLog':'Agent Log',
            'user.userAddMoneyBatch': 'Bulk recharge',
            'user.BatchRegUserLog':'Registration log',
            'key.makeKeyLog':'Code Log',
            'ReportForms.Cut&Money': 'User Statistics',
            'ReportForms.AgentRec': 'number statistics',
            'ReportForms.AgentReg': 'Register statistics',
        },
    },
    //personal center
    'indexData':{
        'view.user': 'Number of users',
        'view.agent': 'Number of subordinate agents',
        'view.key': 'total number of codes',
        'view.money.reg': '[Balance] Registration',
        'view.money.rec': '[Balance] recharge',
        'view.online': 'Number of users online today',
        'msg.loadSuccess': 'Loaded completely',
    },
};
export default lang;