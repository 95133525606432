const pages = [
    {
        //用户统计[代理商用户活跃榜:某个时间段新增的用户数]
        path:'agentReport/userNum',
        name:'agentReport.userNum',
        component:()=>import('../../views/AgentStatistics/userNum'),
    },
    {
        //用户统计[[统计代理商总数]统计某个时间段新增的代理商数量]
        path:'agentReport/agentNum',
        name:'agentReport.agentNum',
        component:()=>import('../../views/AgentStatistics/agentNum'),
    },
    {
        //[用户切割统计]统计下级代理商的用户某个时间段切割数量
        path:'agentReport/cut',
        name:'agentReport.cut',
        component:()=>import('../../views/AgentStatistics/cut'),
    }
];
export default pages;