<template>

<router-view></router-view>
</template>

<script>

export default {
    name: "viewComponents",
    components:{
    }
}
</script>

<style scoped>

</style>