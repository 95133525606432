const lang = {
    'brand':{
        'explain':'用于统计某个时间段哪个品牌最热门的',
    },
    'cut':{
        'explain':'统计自己旗下用户的某个时间段切割排行',
    },
    'KeyByRec':{
        'explain':'用于统计自己旗下的用户某个时间段所使用的充值码金额',
    },
    'model':{
        'explain':'用于统计某个时间段哪个型号最热门的(最大返回前2000条)',
    },
};
export default lang;