/**
 * 多语言类
 */

//加载cookie管理类
import Cookies from 'js-cookie';
//加载语言包
import langs from './loadLang';
//加载[element plus]语言包
import element_zhCn from 'element-plus/lib/locale/lang/zh-cn'
import element_en from 'element-plus/lib/locale/lang/en'

//定义获取语言包函数
const funs = {

    /**
     * 获取指定模块指定页面语言包
     * @param module 模块
     * @param page 页面名字
     * @returns {*[]}
     * 成功返回指定页面语言包,失败返回空对象
     */
    getLang(module,page){
        let language = this.getLanguage();
        var res = {};
        let lang = langs[language];
        if (lang != null){
            if (lang[module] != null){
                if (lang[module][page] != null){
                    res = lang[module][page];
                }
            }
        }
        // console.log('lang',lang)
        return res;
    },

    /**
     * 设置某个国家语言[zh/en/...]
     * @param language 语言
     * @returns {boolean} 成功返回真
     */
    setLanguage(language = 'zh'){
        /**
         * ...设置语言代码
         */
        Cookies.set('language',language);
        return true;
    },

    /**
     * 获取当前语言名字
     * @returns {*}
     */
    getLanguage(){
        let res = Cookies.get('language');
        if (res == null){
            //如果未设置则是中文
            res = 'zh';
            this.setLanguage('zh');
        }
        //如果设置的语言不正确则强制更改为中文
        if (langs[res] == null){
            res = 'zh';
            this.setLanguage('zh');
        }
        return res;
    },

    /**
     * 获取element plus组件语言包
     * @return 语言包[中文/英文]
     * */
    getElementPlusLang(){
        let res = this.getLanguage();
        if (res == 'zh'){
            //中文
            return element_zhCn;
        }
        //英文
        return element_en;
    }
};
export default funs;
