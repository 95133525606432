const pages = [
    {
        //用户切割统计
        path:'userReport/cut',
        name:'userReport.cut',
        component:()=>import('../../views/UserStatistics/cut'),
    },
    {
        //用户充值码使用排行
        path:'userReport/KeyByRec',
        name:'userReport.KeyByRec',
        component:()=>import('../../views/UserStatistics/KeyByRec'),
    },
    {
        //型号排行
        path:'userReport/model',
        name:'userReport.model',
        component:()=>import('../../views/UserStatistics/model'),
    },
    {
        //品牌排行
        path:'userReport/brand',
        name:'userReport.brand',
        component:()=>import('../../views/UserStatistics/brand'),
    },
];

export default pages;