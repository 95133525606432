const lang = {
    //卡密列表
    'keyList':{
        'html.el-button.filter':'筛选数据',
        'html.el-button.refresh':'显示全部',
        'html.el-tag.user':'操作账号',
        'html.el-table-column.label.id':'条形码',
        'html.el-table-column.label.type':'类型',
        'html.el-table-column.label.status':'状态',
        'html.el-table-column.label.money':'额度',
        'html.el-table-column.label.nickname':'备注',
        'html.el-table-column.label.use':'使用账号',
        'html.el-table-column.label.time':'时间',
        'scope.row.type.reg':'充值码',
        'scope.row.type.rec':'注册码',

        'initKeyList.wait':'正在加载数据...',
        'changeTypeAndStatus.wait':'正在更改数据源..',
        'getTableTotal.error.total':'初始化表格数据异常[Total]',
        'getTableTotal.wait':'正在渲染表格..',
        'getTableData.error.render':'渲染卡密列表异常',
        'getStatusStr.1':'已使用',
        'getStatusStr.default':'未使用',
        'recvFilterTableData.tips.successful':'筛选完成,结果数量:',
        'recvFilterTableData.tips.max':'[最大显示前1000条]',
        'recvFilterTableData.error.length':'筛选完成!无符合条件结果',
        'makePdf.tips.wait':'正在生成打印数据,这可能需要点时间...',
    },

    //迁移卡密[自己->下级代理商]
    'keyMove':{
        'submit.res.code.1':'提交参数异常[卡密列表]',
        'submit.res.code.2':'超过最大数量限制',
        'submit.res.code.3':'新代理商不存在',
        'submit.res.code.un':'未知错误',
        'submit.res.data.state.100':'[√]执行成功',
        'submit.res.data.state.1':'[×]卡密不存在',
        'submit.res.data.state.2':'[×]卡密所属不正确',
        'submit.res.data.state.3':'[×]执行异常',

        'html.el-select.placeholder':'迁移的新代理商账号',
        'html.el-input.placeholder':'请输入需要迁移的卡密,一行一条',
        'html.el-tag.tips':'提示:迁移数据需要等待几十秒,请耐心等待,请勿重复提交!',
        'html.el-radio-group.label.1':'卡密',
        'html.el-radio-group.label.2':'条形码',
        'html.el-button.submit':'提交请求',
        'html.el-button.reset':'清空表单',
        'html.el-popconfirm.title':'是否提交请求?',
        'submit.error.newAgent':'请选择新代理商账号',
        'submit.error.keyList':'请输入要迁移的卡密',
        'submit.wait':'正在执行请求..',
        'submit.jump.wait':'执行完成,正在跳转结果页面..',
        'submit.ready.error':'执行完成,但解析结果队列失败',
        'submit.jump.title':'迁移卡密结果',
        'getAgentList.wait':'正在加载代理商列表...',
        'getAgentList.error.agentList':'加载代理商列表异常',
        'getAgentList.error.null':'您没有下级代理商',
    },

    //批量生成卡密
    'keyMake':{
        'onSubmit.res.code.100':'执行成功',
        'onSubmit.res.code.1':'代理账号不存在',
        'onSubmit.res.code.2':'余额不足',
        'onSubmit.res.code.3':'提交参数不符合规则',
        'onSubmit.res.code.4':'提交参数不符合规则',

        'html.button.submit':'提交请求',
        'html.button.reset':'刷新页面',
        'html.el-form-item.keyNum':'生成数量',
        'html.el-form-item.money':'*卡密额度',
        'html.el-form-item.nickname':'卡密备注',
        'html.el-input .placeholder':'(选填)每张卡密的备注',
        'html.el-form-item.type':'卡密类型',
        'html.el-radio.1':'注册码',
        'html.el-radio.2':'充值码',
        'html.el-tag.reg':'注册',
        'html.el-tag.rec':'充值',
        'html.span.tips.title':'[money]卡密额度限制:',
        'html.span.tips.reg':'注册码:1~50',
        'html.span.tips.rec':'充值码:1~5000',

        'getSelfMoney.error':'查询余额异常',
        'onSubmit.error.keyNum':'超过数量限制[1~2000]',
        'onSubmit.error.money':'(money)卡密额度[1~5000]',
        'onSubmit.wait':'正在执行请求...',
    },
    //批量回收卡密[自己的卡密]
    'recovery':{
        'submit.res.state.title':'回收卡密结果',
        'submit.res.state.100':'[√]执行成功',
        'submit.res.state.1':'[×]卡密不存在',
        'submit.res.state.2':'[×]代理商不存在',
        'submit.res.state.3':'[×]卡密已使用',
        'submit.res.state.4':'[×]操作代理商账号与卡密所属代理商不一致',
        'submit.res.state.5':'[×]执行异常',

        'html.el-input':'请输入需要回收的卡密,一行一条',
        'html.el-radio-group.label.1':'卡密',
        'html.el-radio-group.label.2':'条形码',
        'html.el-tag.placeholder.tips.wait':'提示:回收数据需要等待几十秒,请耐心等待,请勿重复提交!',
        'html.el-tag.placeholder.tips.keyStatus':'*注意:请确保待回收的卡密尚未使用!',
        'html.el-button.submit':'提交请求',
        'html.el-button.reset':'清空表单',
        'html.el-popconfirm.title':'是否提交请求?',

        'submit.errot.keyList':'请输入要迁移的卡密',
        'submit.wait':'正在执行请求..',
        'submit.result.jump':'执行完成,正在跳转结果页面..',
        'submit.result.error':'执行完成,但解析结果队列失败',
    },
    //[卡密列表]筛选器
    'keyList-filter':{
        'html.el-form-item.key':'卡密',
        'html.el-form-item.id':'条形码',
        'html.el-form-item.use':'使用者',
        'html.el-form-item.time':'时间',
        'html.el-tag.tips':'提示:填写任意一个条件即可!',
        'html.button.submit':'筛选数据',
        'html.button.return':'取消操作',
        'html.button.resetForm':'清空表单',

        'submit.error.filterConfig':'请设置要筛选的条件',
        'submit.wait':'正在准备数据..',
        'getFilterTableData.error.filter.1':'筛选数据异常',
        'getFilterTableData.error.filter.2':'*筛选数据异常[2]',

        'html.el-form-item.nickname':'备注',
    },
    //回迁卡密[下级代理商->自己]
    'keyTakeBackKey':{
        'html.el-input.placeholder':'请输入需要从下级代理商回迁到自己的卡密,一行一条',
        'html.el-tag.tips':'*提示:迁回数据需要时间,请耐心等待!请确保卡密是您下级代理商!',
        'html.el-button.submit':'提交请求',
        'html.el-button.reset':'清空表单',
        'html.el-popconfirm.title':'是否执行操作?',

        'submit.error.keyList':'请输入要回迁的卡密',
        'submit.wait':'正在执行请求..',
        'submit.jump.wait':'执行完成,正在跳转结果页面..',
        'submit.ready.error':'执行完成,但解析结果队列失败',
        'submit.jump.title':'回迁卡密结果(下级代理商->自己)',

        'submit.res.code.1':'提交参数异常[卡密列表]',
        'submit.res.code.2':'超过最大数量限制',

        'submit.res.code.un':'未知错误',
        'submit.res.data.state.100':'[√]执行成功',
        'submit.res.data.state.1':'[×]查询卡密信息异常',
        'submit.res.data.state.2':'[×]卡密所属不正确',
        'submit.res.data.state.3':'[×]执行异常',
    },

    //[卡密列表]数据模式选择
    'keyList-modeMenu':{
        'html.el-button.type':'模式切换',
        'html.el-button.status':'类型切换',
        'html.el-dropdown-item.changeType.1':'[类型]注册码',
        'html.el-dropdown-item.changeType.2':'[类型]充值码',
        'html.el-dropdown-item.changeType.3':'[状态]未使用',
        'html.el-dropdown-item.changeType.4':'[状态]已使用',
        'html.el-dropdown-item.changeType.5':'[状态]全部',
        'html.el-tag.type':'卡密状态',
        'html.el-tag.status':'使用类型',

        'getTypeStr.type.1':'注册码',
        'getTypeStr.type.2':'充值码',
        'getTypeStr.type.3':'全部卡密',
        'getTypeStr.type.default':'未知类型',

        'getStatusStr.status.0':'未使用',
        'getStatusStr.status.1':'已使用',
        'getStatusStr.status.3':'全部卡密',
        'getStatusStr.status.default':'异常状态',
    },
    //[卡密列表]工具栏
    "keyList-ToolsMenu":{
        'html.el-dropdown-item.Refresh':'刷新页面',
        'html.el-dropdown-item.outputSelectKey':'导出选中',
        'html.el-dropdown-item.moveKey':'迁移卡密',
        'html.el-dropdown-item.recoveryKey':'回收卡密',
        'html.el-dropdown-item.takeBackKey':'迁回卡密',

        'getSelectArr.tips':'请选择要操作的卡密',
        'outputSelectKey.wait':'正在执行导出数据...',
        'moveKey.wait':'正在准备迁移卡密..',
        'recoveryKey.wait':'正在准备数据..',
        'takeBackKey.wait':'正在准备数据..',
    },
    'makeKeyLog':{
        'html.DownLoadLog.Describe':'批量生成卡密记录',
    },
    'keyDataToPdf':{
        'html.el-button.refresh':'刷新页面',
        'html.el-button.print':'打印页面',
        'html.el-button.makePdf':'生成PDF',

        'makePdf.wait':'正在生成文件,请耐心等待...',
        'onMounted.error.keyData':'解析数据异常[0x001]',
        'onMounted.error.array':'解析数据异常[0x002]',
    },
};

export default lang;