/**
 * 本地存储对象[localStorage]
 */
//定义存储对象
const obj = localStorage;
const fun = {

    /**
     * 基于[模块+key]生成字段名
     * @param module 模块名
     * @param key
     * @returns {string}
     */
    getField(module,key){
      let str = module + '-' + key;
      return str;
    },

    /**
     * [文本型]写入
     * @param module 模块名
     * @param key
     * @param str
     */
    saveString(module,key,str){
        let field = this.getField(module,key);
        obj.setItem(field,str);
        // console.log('存储对象.saveString',field,str)
    },

    /**
     * [文本型]读取
     * @param module 模块名
     * @param key
     * @returns {string}
     */
    getString(module,key){
        let field = this.getField(module,key);
        let str = obj.getItem(field)
        // console.log('存储对象.getString 字段名:' + field,str)
        return str;
    },

    /**
     * [对象/数组]保存
     * @param module 模块名
     * @param key
     * @param obj
     */
    saveObject(module,key,obj){
        let json = JSON.stringify(obj);
        this.saveString(module,key,json);
    },

    /**
     * [对象/数组]读取
     * @param module 模块名
     * @param key
     * @returns {any}
     */
    getObject(module, key){
        let json = this.getString(module, key);
        let obj = JSON.parse(json);
        return obj;
    },

    /**
     * 清空全部存储
     */
    clearAll(){
        obj.clear();
    }
};
export default fun;