<template>
    <div>
        <el-result
            icon="error"
            title="Error"
            sub-title="The requested URL / was not found on this server"
        >

        </el-result>
    </div>
</template>

<script>
export default {
    name: "notFound"
}
</script>

<style scoped>

</style>