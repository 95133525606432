const pages = [
    {
        //用户统计[余额+切割次数+总]
        path:'ReportForms/CutMoney',
        name:'ReportForms.CutMoney',
        component:()=>import('../../views/ReportForms/CutMoney'),
    },
    {
        //代理统计[次数]
        path:'ReportForms/AgentRec',
        name:'ReportForms.AgentRec',
        component:()=>import('../../views/ReportForms/AgentRec'),
    },
    {
        //代理统计[次数]
        path:'ReportForms/AgentReg',
        name:'ReportForms.AgentReg',
        component:()=>import('../../views/ReportForms/AgentReg'),
    },
]

export default pages;