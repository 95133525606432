// 公共页面
import storage from '../../utils/storage';
const state = {
    /**
     * [结果页面] 类型:对象
     * data:结果数据 [数组对象] 对象需包含(id object state)
     * title:标题
     * lang:包含[state]状态的多语言
     * langCodeFiled 结果字段前缀
     */
    result:storage.getObject('common','result'),
}
const getters = {
    /**
     * [结果页面]获取数据
     * @param state
     * @returns {*}
     */
    get_result(state){
        return state.result;
    }
};
const mutations = {
    /**
     * [结果页面]写入信息
     * @param state
     * @param obj 要写入的内容[对象]
     */
    set_result(state,obj){
        state.result = obj;
        storage.saveObject('common','result',obj);
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations
};