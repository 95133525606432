//引入[vue-router]
import {
    createRouter,
    //[历史模式]url模式
    // createWebHashHistory as mode,
    createWebHistory as mode,
} from 'vue-router';

//引入页面配置
import pages from "./pages";

//创建路由实例并传递 `routes` 配置
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: mode(),
    routes:pages, // `routes: routes` 的缩写
});

export default router