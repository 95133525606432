const lang = {
    'brand':{
        'explain': 'Used to count which brand is the most popular in a certain period of time',
    },
    'cut':{
        'explain': 'Statistics of their own users cutting rankings for a certain time period',
    },
    'KeyByRec':{
        'explain': 'It is used to count the amount of recharge code used by its own users in a certain period of time',
    },
    'model':{
        'explain': 'Used to count which model is the most popular in a certain period of time (maximum return to the top 2000)',
    },
};
export default lang;