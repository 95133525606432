import Cookie from 'js-cookie'

//定义Token存储字段名
const authName = 'authUser';

const func = {
    /**
     * 设置cookie
     * @param name 字段名
     * @param value 值
     * @returns {*}
     */
    setCookie(name,value){
        return Cookie.set(name,value)
    },

    /**
     * 获取指定字段cookie
     * @param name 字段名
     * @returns {*}
     */
    getCookie(name){
        return Cookie.get(name);
    },

    /**
     * 设置校验令牌
     * @param token
     * @returns {*}
     */
    setToken(token){
        return this.setCookie(authName,token);
    },

    /**
     * 获取已设置的校验令牌
     * @returns {*}
     */
    getToken(){
        let res = this.getCookie(authName);
        return res;
    }

};
export default func;