const lang = {
    //[批量生成卡密/批量注册用户]共用组件
    'DownLoadLog':{
        'html.el-button.refresh':'刷新页面',
        'html.el-table-column.id':'序号',
        'html.el-table-column.file':'记录名',
        'html.el-button.downloadFile':'下载',
    },
    'ReportView':{
        'html.Router':'刷新页面',
        'html.OutDataToFile':'导出数据',
        'html.checkbox':'包含下级',
        'html.Search':'搜索数据',
        'html.input.placeholder':'请输入搜索的关键词请输入搜索的关键词',
        'html.checkbox.false':'取消',
        'html.checkbox.SearchData':'搜索',
        'SetTableDataArr.tips':'已加载报表数量',
    }
};
export default lang;