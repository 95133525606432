/**
 * 加载语言包
 */
import zh from '../locales/zh/load';
import en from '../locales/en/load';

const langs = {
    'zh':zh,
    'en':en,
};

export default langs;
