import { createApp } from 'vue'
import App from './App.vue'
//[vue-router]
import router from "./router/index";
//axios
import api from "./api/axiosConfig";
//element Plus
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
//加载多语言
import lang from './lang/index';
//加载Cookie类
import cookies from "./utils/cookies";
import * as ElIconModules from '@element-plus/icons';
//助手函数
import helper from "./utils/common";
//Vuex
import Vuex from './Vuex/index';
//Vue打印页面库
import Print from 'vue3-print-nb'

let vm = createApp(App);
vm.use(router);
vm.use(ElementPlus);
vm.use(Vuex);
vm.use(Print)

//全局挂载库
vm.config.globalProperties.$api = api;
vm.config.globalProperties.$lang = lang;
vm.config.globalProperties.$cookie = cookies;
vm.config.globalProperties.$helper = helper;
//导入icon
for (const iconName in ElIconModules) {
    if (Reflect.has(ElIconModules, iconName)) {
        const item = ElIconModules[iconName]
        vm.component(iconName, item)
    }
}
vm.mount('#app');
