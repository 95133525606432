/**
 * 加载中文[zh]模块语言包
 */
//界面布局
import layout from './layout/index';
//login模块
import login from './login/index'
//user模块
import user from './user/index';
//agent模块
import agent from './agent/index'
//[公用]common模块
import common from "./common/index";
//key模块
import key from './key/index';
//日志模块
import log from './log/index';
//代理统计
import agentStatistics from './agentStatistics/index';
//用户统计
import userStatistics from './userStatistics/index';
//通用组件
import components from './components/index';
import ReportForms from "./ReportForms/index";

const langs = {
    'layout':layout,
    'login' : login,
    'user':user,
    'agent':agent,
    'common':common,
    'key':key,
    'log':log,
    'agentStatistics':agentStatistics,
    'userStatistics':userStatistics,
    'components':components,
    'ReportForms':ReportForms,
}

export default langs;