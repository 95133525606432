const lang = {
    //[Batch generation of card codes / batch registration of users] common components
    'DownLoadLog': {
        'html.el-button.refresh': 'Refresh the page',
        'html.el-table-column.id': 'serial number',
        'html.el-table-column.file': 'record name',
        'html.el-button.downloadFile': 'Download',
    },
    'ReportView': {
        'html.Router': 'Refresh the page',
        'html.OutDataToFile': 'Export data',
        'html.checkbox': 'Includes subordinates',
        'html.Search': 'Search data',
        'html.input.placeholder': 'Please enter the search keywords please enter the search keywords',
        'html.checkbox.false': 'Cancel',
        'html.checkbox.SearchData': 'Search',
        'SetTableDataArr.tips': 'Number of reports loaded',
    }
};
export default lang;