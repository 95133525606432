const lang = {
    //结果页面[result.vue]
    'result':{
        'html.button':'返回上级',
        'html.el-tag':'结果数量',
        'mounted.wait':'正在渲染数据..',
        'mounted.success':'数据已渲染',
        'mounted.fail':'载入数据异常',
    }
};

export default lang;