const pages = [
    {
        //卡密列表
        /**
         * [agent]查询账号(self为自己)
         * 卡密类型[type]: 1=注册码 2=充值码 3=全部
         * 卡密状态[status]: 0=未使用 1=已使用 3=全部
         */
        path:'key/keyList/:agent/:type/:status/',
        name:'key.keyList',
        component:()=>import('../../views/Key/keyList'),
    },
    {
        //迁移卡密
        path:'key/moveKey/:keyList?',
        name:'key.moveKey',
        component:()=>import('../../views/Key/keyMove'),
    },
    {
        //批量生成卡密
        path:'key/makeKey',
        name:'key.makeKey',
        component:()=>import('../../views/Key/keyMake'),
    },
    {
        //回收卡密[自己的卡密]
        path:'key/recovery',
        name:'key.recovery',
        component:()=>import('../../views/Key/key-recovery')
    },
    {
        //回迁卡密[下级代理商->自己]
        path: 'key/keyTackBackKey/:keyList',
        name: 'key.keyTackBackKey',
        component:()=>import('../../views/Key/keyTakeBackKey'),
    },
    {
        //批量生成卡密日志
        path: 'key/makeKeyLog',
        name: 'key.makeKeyLog',
        component:()=>import('../../views/Key/makeKeyLog'),
    },
    {
        //卡密信息生成PDF
        path:'key/keyDataToPdf',///:keyData
        name:'key.keyDataToPdf',
        component:()=>import('../../views/Key/keyDataToPdf'),
    },
];
export default pages;