//引入[vuex]
import {
    createStore
} from 'vuex';
//引入模块化数据
import module_common from './module/common';
//创建模块化
const store = createStore({
    //模块化
    modules:{
        common:module_common
    },
});

export default store;