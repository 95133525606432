const pages = [
    {
        //代理列表
        path : 'agent/agentList',
        name : 'agent.agentList',
        component:()=>import('../../views/Agent/agentList')
    },
    {
        //添加下级代理
        path: 'agent/agentAdd',
        name: 'agent.addAgent',
        component:()=>import('../../views/Agent/agentAdd'),
    },
    {
        //修改自己密码页面
        path: 'agent/setSelfNewPass',
        name:'agent.setSelfNewPass',
        component:()=>import('../../views/Agent/setSelfNewPass')
    },
    {
        //下级用户管理
        path: 'agent/subordinateUsers/:agent',
        name: 'agent.subordinateUsers',
        component: () => import('../../views/Agent/subordinateUsers'),
    }
];

export default pages;