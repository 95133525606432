const lang = {
    'index':{
        'title':'System login',
        'form.user': 'Please enter the user name',
        'form.pass': 'Please enter your password',
        'form.login': 'login',
        'form.reset': 'Reset',
        'form.user-pass.tips': 'Please enter the completely account password',
        'tips.wait': 'Login, please wait...',
        'tips.succ': 'Login succeed, jumping..',
        'tips.fail': 'Login failed',
        'tips.version': 'Incorrect version'
    },
    'alertTips':{
        'html.span.tips': 'It is recommended to use the latest Google Chrome browser, do you want to download it?',
        'html.el-checkbox.label': 'No more prompts',
        'html.el-button.clearView': 'I know it',
        'html.el-button.toDonwload': 'Go to download',
    },
};
export default lang;