import axios from 'axios';
// import { ElMessageBox } from 'element-plus';
import cookies from "../utils/cookies";
const config = {
    // baseURL: process.env.baseURL
    // baseURL: 'http://linux.com/AgentApi/',//本地调试专用
    // baseURL: 'http://api.x--9.com/AgentApi/',//[120服务器]调试专用
    // baseURL: 'https://httpsapi.x--9.com/AgentApi/',//[120服务器]调试专用(https)
    // baseURL: 'https://console.mjkj365.com/AgentApi/',//魔将1号服务器
    // baseURL: 'https://console.mjkj1688.com/AgentApi/',//魔将2号服务器
    baseURL: 'https://console.x--0.com/AgentApi/',//新思睿服务器
    // baseURL: 'https://console.filmcuttingmachine.com/AgentApi/',//千机变
    // baseURL: 'https://console.dhj365.com/AgentApi/',//膜将3号服务器
    timeout: 1000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
};

const api = axios.create(config);

// 默认 post 请求，使用 application/json 形式
api.defaults.headers.post['Content-Type'] = 'application/json';


//封装下post
api.post = function(url,params){
    return new Promise((resolve, reject) => {
        // console.log("****************************");
        axios({
            method: 'post',
            url:config.baseURL + url,
            data:{
                ...params,
                version:cookies.getCookie('version')
            },
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'authUser': cookies.getToken(),
            },
        }).then(response => {
            if (response.status == 200) {
                //根据实际情况进行更改
                resolve(response)
            } else {
                reject(response)
            }
        })
    })

}

// http response 拦截器
api.interceptors.response.use(
    response => {
        //拦截响应，做统一处理
        console.log('[拦截器]response',response)
        return Promise.resolve(response)
    },
    //接口错误状态处理，也就是说无响应时的处理
    error => {
        console.log('!!!errr')
        return Promise.reject(error.response.status) // 返回接口返回的错误信息
    })

export default api;