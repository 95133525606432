const lang = {
    'agentNum':{
        'explain': 'Used to count the number of new agents at the next level in a certain period of time',
    },
    'cut':{
        'explain': 'Count the number of cuts in a certain period of time by users of subordinate agents',
    },
    'userNum':{
        'explain': 'Used to count the number of new users added by subordinate agents in a certain period of time',
    },
    //[template] folder in the directory
    'template.viewFilterFromTime':{
        'html.el-form-item.label': 'scope',
        'html.el-button.filter': 'Filter data',
        'html.el-button.return': 'Cancel filter',
        'html.el-button.reset': 'Filter error',
    },
    'template.viewOneClickTime':{
        'html.el-button.filter': 'Quick filter',
    },
    'template.viewPagination':{
        'html.el-tag.page':'Current page number',
    },
    'template.viewTable':{
        'html.el-button.refresh': 'Refresh the page',
        'html.el-button.alertTips': 'Data Description',
        'html.el-button.openFilterTime': 'Filter time',
        'html.el-button.outputReport':'Export data',
        'html.el-tag.time': 'Generation time',
        'html.el-tag.all': 'Sum report',
        'html.element-loading-text': 'Data is being generated, please do not close the page..',
        'html.el-checkbox.label': 'includes subordinates',
        'html.el-table.loading': 'Please be patient and wait for the generation to complete..',
        'html.el-table-column.id':'Serial number',
        'html.el-table-column.object':'Account',
        'html.el-table-column.result': 'data',
        'getReportData.wait': 'Requesting report data..[It may take a few seconds]',
        'getReportData.error.null': 'Request data error',
        'recvFilterNewValue.tips': 'Entered filter mode time:',
        'outputReport.wait': 'Preparing to export data..',
        'outputReport.success': 'Export data completed',
    },
};
export default lang;