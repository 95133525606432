const lang = {
    AgentRec:{
        'TableColumn.label.id':'序号',
        'TableColumn.label.leader':'上级',
        'TableColumn.label.agent':'代理',
        'TableColumn.label.use':'次数/用',
        'TableColumn.label.money':'次数/余',
        'TableColumn.label.all':'次数/总',
        'el-button.sort':'排序数据',
    },
    AgentReg:{
        'TableColumn.label.id':'序号',
        'TableColumn.label.leader':'上级',
        'TableColumn.label.agent':'代理',
        'TableColumn.label.use':'注册/用',
        'TableColumn.label.money':'注册/余',
        'TableColumn.label.all':'注册/总',
        'el-button.sort':'排序数据',
    },
    CutMoney:{
        'TableColumn.label.id':'序号',
        'TableColumn.label.agent':'代理商',
        'TableColumn.label.username':'账号',
        'TableColumn.label.cut':'切割',
        'TableColumn.label.money-1':'余额',
        'TableColumn.label.money-2':'到期时间',
        'TableColumn.label.all-1':'总计',
        'TableColumn.label.UserMoneyDay':'天数',
        'el-button.sort':'排序数据',
    }
};
export default lang;